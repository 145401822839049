*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  font-family: "Quicksand", sans-serif;
}
:root {
  --robotoFont: "Roboto", sans-serif;
  --quicksandFont: "Quicksand", sans-serif;
  --poppinFont: "Poppins", sans-serif;
  --interFont: "Inter", sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');