.checkoutpage1{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}
.checkoutpage2{
    width: 90%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.checkoutpage3{
    width: 100%;
}
.checkpage4{
    width: 40%;
    padding-left: 20px;
}
@media screen and (max-width:768px) {
    .checkoutpage1{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-top: 75px;
    }
    .checkoutpage2{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }
    .checkoutpage3{
        width: 100%;
    }
    .checkpage4{
        width: 100%;
        padding-left: 0px;
    }
}