marquee{
    height: 30px;
    display: flex;
    margin-top: 70px;
    padding: 20px 0px;
    display: flex;
    align-items: center;
}
@media screen and (max-width:768px){
    marquee{
        height: 30px;
        display: flex;
        font-size: 16px;
        margin-top: 85px;
    }
}