.LoginComp {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0px;
    background-image: url("../Assets/images/62745\ 1.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.Logincomp1 {
    width: 75%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Logincomp3 {
    width: 90%;
    margin: 30px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.MostlyViewed {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.MostlyViewed h3 {
    font-size: 16px;
}

.remove {
    color: red;
    font-size: 20px;
    cursor: pointer;
}

.remove:hover {
    color: red;
    transform: scale(1.3);
    cursor: pointer;
    transition: 0.2s;
}

@media screen and (max-width:768px) {
    .LoginComp {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px 20px;
        /* background-image: url("../Assets/images/62745\ 1.svg"); */
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 75PX;
    }

    .Logincomp1 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .Logincomp3 {
        width: 100%;
        margin: 30px 0px;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        grid-gap: 20px;
    }

    .MostlyViewed {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0px;
    }

    .MostlyViewed h3 {
        font-size: 14px;
    }

    .remove {
        color: red;
        font-size: 20px;
        cursor: pointer;
    }
}