.Carts {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.cartcount {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.cartqty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    margin-top: 10px;
}

.cartqty h4 {
    font-size: 13px;
    padding-bottom: 6px;
}

.incre {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid red;
    border-radius: 10px;
    padding: 2px 10px;
    color: red;
}

.cartstop {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 15px 15px 15px;
    position: relative;

}

.cartstop .Cartinnerimg {
    width: 100%;
    height: 200px;
    display: flex;
    border-radius: 10px;
}

.cartstop .Cartinnerimg h2 {
    position: absolute;
    background-color: red;
    color: white;
    top: 0;
    left: 0;
    font-size: 10px;
    padding: 8px;
    border-top-left-radius: 10px;
}

.Cartinnerimg span {
    position: absolute;
    background-color: lightgray;
    position: absolute;
    padding: 6px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    right: 10px;
}

.favicon {
    font-size: 13px;
    color: red;
}

.cartstop .Cartinnerimg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;

}

.cartstop .Cartinnercontent {
    width: 100%;
    margin-top: 20px;
}

.Cartinnercontent span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px;
}

.Cartinnercontent span del {
    margin-left: 20px;
    color: gray;
    font-size: 12px;

}

.Cartinnercontent span h4 {
    color: red;
}

.ratingicon {
    font-size: 15px;
    color: red;
}

.ratingimg img {
    height: 70px;
    width: 70px;
}

.Carts button {
    width: 100%;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    background-color: red;
    cursor: pointer;
    color: white;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
}

.incre p {
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .Carts {
        width: 100%;
        height:auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        border: 1px solid lightgray;
        border-radius: 10px;
        }

    .cartstop {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 40px 15px 15px 15px;
        position: relative;

    }

    .cartstop .Cartinnerimg {
        width: 100%;
        height: 100px;
        display: flex;
        border-radius: 10px;
    }

    .cartstop .Cartinnerimg h2 {
        position: absolute;
        color: white;
        top: 0;
        left: 0;
        font-size: 10px;
        padding: 8px;
        border-top-left-radius: 10px;
    }

    .Cartinnerimg span {
        position: absolute;
        background-color: lightgray;
        position: absolute;
        padding: 6px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 5px;
        right: 10px;
    }

    .favicon {
        font-size: 13px;
        color: red;
    }

    .cartstop .Cartinnerimg img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;

    }

    .cartstop .Cartinnercontent {
        width: 100%;
        margin-top: 20px;
        height: auto;
    }

    .Cartinnercontent span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0px;
    }

    .Cartinnercontent span del {
        margin-left: 20px;
        color: gray;
        font-size: 12px;

    }

    .Cartinnercontent span h4 {
        color: red;
    }

    .ratingicon {
        font-size: 15px;
        color: red;
    }

    .ratingimg {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .ratingimg img {
        height: 70px;
        width: 70px;
    }

    .Carts button {
        width: 100%;
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: none;
        background-color: red;
        cursor: pointer;
        color: white;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
    }

    .Cartinnercontent h4 {
        text-align: start;
    }
    .cartcount{
        flex-direction: column;
    }
}