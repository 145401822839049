.Cartprice {
    width: 100%;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    box-shadow: 0 4px 12px 0 rgb(251, 251, 251);

}

.Cartprice1 {
    width: 100%;
    padding: 20px;
    background-color: red;
    color: white;
}

.Cartprice2 {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gainsboro;
}

.checkoutbtn {
    width: 100%;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    background-color: red;
    cursor: pointer;
    color: white;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    font-family: "Roboto", sans-serif;
}

.erromsg {
    color: red;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    margin: 10px;
}

.Pricelist p {
    margin: 20px 0px;
    display: flex;
}

.Totalprice {
    width: 100%;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
@media screen and (max-width:768px){
    .Cartprice {
        width: 100%;
        background-color: #FAFAFA;
        display: flex;
        flex-direction: column;
        margin: 30px 0px;
        box-shadow: 0 4px 12px 0 rgb(251, 251, 251);
    
    }
    
    .Cartprice1 {
        width: 100%;
        padding: 20px;
        background-color: red;
        color: white;
    }
    
    .Cartprice2 {
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid gainsboro;
    }
    
    .checkoutbtn {
        width: 100%;
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: none;
        background-color: red;
        cursor: pointer;
        color: white;
        font-weight: 600;
        justify-content: center;
        text-align: center;
        font-family: "Roboto", sans-serif;
    }
    
    .erromsg {
        color: red;
        font-size: 13px;
        font-weight: 700;
        text-align: center;
        margin: 10px;
    }
    
    .Pricelist p {
        margin: 20px 0px;
        display: flex;
    }
    
    .Totalprice {
        width: 100%;
        padding: 20px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
    }
}