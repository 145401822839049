.tablestate {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F4F4F4AD;
    padding: 20px;
    margin-top: 0px;

}

.tablestate1 {
    display: flex;
    flex-direction: column;
}

.tablestate1 h1 {
    font-size: 20px;
    margin-bottom: 10px;
}

.tablestate1 p {
    font-size: 15px;
    color: #ff1414;
    font-weight: bolder;
}

.tablestate h5 {
    color: #707070;

}

.timage {
    width: 50px;
    height: 50px;
    display: flex;
}

.timage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.checklog {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center
}

.checklog1 {
    width: 90%;
    height: 100%;
    display: flex;
}

.cartqty1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 55%;
    margin-top: 10px;
}

.cartqty1 h4 {
    font-size: 13px;
    padding-bottom: 6px;
}

.incre1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid red;
    border-radius: 10px;
    padding: 2px 10px;
    color: red;
}

.incre1 p {
    cursor: pointer;
}


@media screen and (max-width:768px) {
    .tablestate {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F4F4F4AD;
        padding: 20px;
        margin-top: 30px;

    }

    .tablestate1 {
        display: flex;
        flex-direction: column;
    }

    .tablestate1 h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .tablestate1 p {
        font-size: 15px;
        color: #ff1414;
        font-weight: bolder;
    }

    .tablestate h5 {
        color: #707070;

    }

    .timage {
        width: 50px;
        height: 50px;
        display: flex;
    }

    .timage img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .checklog {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    .checklog1 {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0;
    }

    .cartqty1 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;
    }

    .cartqty1 h4 {
        font-size: 13px;
        padding-bottom: 6px;
    }

    .incre1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid red;
        border-radius: 10px;
        padding: 2px 5px;
        color: red;
    }

}