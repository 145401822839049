.Ourservices {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
}

.Ourservices1 {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Ourservices2 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-gap: 60px;
}

.Ourservices1 h1 {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    margin: 30px 0px 40px 0px;
}

.Ourservices h1 span {
    color: red;
}

.Ourservicescart {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: lightgray;
}

.Ouricon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Ouricon .delviericon {
    font-size: 50px;
    color: red;
}

.Ourservicescart p {
    width: 80%;
    text-align: center;
    line-height: 30px;
    color: gray;
}

.Ourservicescart h1 {
    font-size: 25px;
    margin: 10px 0px;
}

.Ourservicescart img {
    width: 100%;
    height: 100%;
    object-fit: unset;
    border-radius: 10px;
}

@media screen and (max-width:768px) {
    .Ourservices {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px;
        padding: 20px;
    }

    .Ourservices1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .Ourservices2 {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(1, 6fr);
        grid-gap: 60px;
    }

    .Ourservices1 h1 {
        font-family: "Roboto", sans-serif;
        font-size: 32px;
        margin: 30px 0px 40px 0px;
    }

    .Ourservices h1 span {
        color: red;
    }

    .Ourservicescart {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: lightgray;
    }

    .Ouricon {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Ouricon .delviericon {
        font-size: 50px;
        color: red;
    }

    .Ourservicescart p {
        width: 80%;
        text-align: center;
        line-height: 30px;
        color: gray;
    }

    .Ourservicescart h1 {
        font-size: 25px;
        margin: 10px 0px;
    }

    .Ourservicescart img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 10px;
    }
}