.checkviewcart{
    width: 90%;
    height: auto;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    border-bottom: 1px solid gainsboro;
}
.checkviewcartimg{
    margin-right: 20px;
}
.checkviewcartcontent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.checkviewcartcontent h4{
    margin-bottom: 6px;
}

.checkviewcartcontent p span{
    color: red;
    margin-right: 8px;
}
@media screen and (max-width:768px){
    .checkviewcart{
        width: 100%;
        height: auto;
        margin: 20px 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px;
        border-bottom: 1px solid gainsboro;
    }
    .checkviewcartimg{
        width: auto;
        height: auto;
        margin-right: 20px;
    }
    .checkviewcartimg img{
width: 100%;
height: 100%;
object-fit: cover;
    }
    .checkviewcartcontent{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .checkviewcartcontent h4{
        margin-bottom: 6px;
        font-size: 10px;
    }
    .checkviewcartcontent p{
        font-size: 10px;
    }
    
    .checkviewcartcontent p span{
        color: red;
        margin-right: 8px;
    }
    
}