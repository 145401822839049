.CrackerBanner{
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
}
.CrakerBannner1{
    width: 90%;
    height: 80%;
    display: flex;
     border-radius: 10px;
}
.CrakerBannner1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;

}
@media screen and (max-width:768px){
    .CrackerBanner{
        width: 100%;
        height: 25%;
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: center;
        }
        .CrakerBannner1{
            width: 100%;
            height: 100%;
            display: flex;
             border-radius: 10px;
        }
        .CrakerBannner1 img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        
        }
}