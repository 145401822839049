.details{
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.detailinner{
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.detailsright{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}
.detailsimg{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.detailsimg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;

}
.detailsright h3{
    color: red;
    margin-bottom: 6px;
    font-weight: normal;
}
.detailsright h1{
    color: black;
    font-family: "Roboto", sans-serif;
}
.detailsright h1 span{
    color: red;
}
.detailsright p{
    width: 70%;
    line-height: 30px;
    font-size: 17px;
    text-align: start;
    margin: 15px 0px;
    font-weight: 600;
    color: gray;
}
.detailsright button{
    width: 150px;
    padding: 13px 15px;
    background-color: red;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px 2px 18px 2px;
    font-size: 16px;
    margin-top: 20px;

}
.detailsright button:hover{
    background-color: black;
    color: white;
    transition: .5s;
    cursor: pointer;
    border-radius: 2px 18px 2px 18px;
}
.detailsright button .btnicon{
margin-left: 10px;
font-size: 16px;
}

@media screen and (max-width:768px) {
    .details{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
        padding: 0px 20px 20px 20px;
    }
    .detailinner{
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .detailsright{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }
    .detailsimg{
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        margin-top: 20px;
    }
    .detailsimg img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
    
    }
    .detailsright h3{
        color: red;
        margin-bottom: 6px;
        font-weight: normal;
    }
    .detailsright h1{
        color: black;
        font-family: "Roboto", sans-serif;
    }
    .detailsright h1 span{
        color: red;
    }
    .detailsright p{
        width: 100%;
        line-height: 30px;
        font-size: 17px;
        text-align: justify;
        margin: 15px 0px;
        font-weight: 600;
        color: gray;
    }
    .detailsright button{
        width: 150px;
        padding: 13px 15px;
        background-color: red;
        color: white;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    font-size: 16px;
    margin-top: 20px;
    
    }
    .detailsright button .btnicon{
    margin-left: 10px;
    font-size: 16px;
    }
}