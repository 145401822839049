.Register{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 30px 0px;
    box-shadow:  0 8px 32px gainsboro;
    background: #FAFAFA;
    
}
.RegisterHead{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.RegisterHead h1{
    font-size: 25px;
    margin: 10px 0px;
}
.RegisterHead p{
    font-weight: 600;

}
.RegisterHead p span{
    color: red;
    font-weight: 600;
    border-bottom: 1px solid red;
}
.Personalinfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
}
.Personalinfo h3{
    width: 100%;
    text-align: start;
}
.checklogin{
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:768px) {
    .Register{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin: 30px 0px;
        box-shadow:  0 8px 32px gainsboro;
        background: #FAFAFA;
        
    }
    .RegisterHead{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .RegisterHead h1{
        font-size: 25px;
        margin: 20px 0px;
    }
    .RegisterHead p{
        width: 100%;
        text-align: center;
        font-weight: 600;
        margin-bottom: 10px;
    
    }
    .RegisterHead p span{
        color: red;
        font-weight: 600;
        border-bottom: 1px solid red;
    }
    .Personalinfo{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0px;
    }
    .Personalinfo h3{
        width: 100%;
        text-align: start;
    }
    .checklogin{
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .checkname{
        flex-direction: column;
    }
    .checkname input{
        background-color: #fafafa;
    }
    .checkname1{
        width: 100%;
    }
    .checkname1 h3{
        width: 0;
    }
    .checkoutouter{
        width: 100%;
        padding: 0;
    }
    .checkoutouter h3{
        margin-bottom: 20px;
    }
}