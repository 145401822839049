.Banner {
  width: 100%;
  height: 100%;
  z-index: -1;
  margin-top: 100px;
}

.Banner .swiper {
  width: 100vw;
  height: 100vh;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.Banner .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Banner .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Banner .swiper {
  margin-left: auto;
  margin-right: auto;
}

.Banner .myslider {
  width: 100%;
  height: 100%;
}

.Banner .myslider img {
  width: 100%;
  height: 100%;
}

.design {
  width: 100%;
  height: 100vh;
}

.design img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width:768px) {
  .Banner {
    width: 100%;
    height: 300px;
  }

  .Banner .swiper {
    width: 100%;
    height: 30vh;
  }

  .Banner .swiper-slide {
    width: 100%;
    height: 200px;
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .Banner .swiper {
    margin-left: auto;
    margin-right: auto;
  }

  .Banner .myslider {
    width: 100%;
    height: 100%;
  }

  .Banner .myslider img {
    width: 100%;
    height: 200px;

  }

  .design {
    width: 100%;
    height: 200px;
  }

  .design img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

}