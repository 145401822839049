.ThankingContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.DwldIc {
    font-weight: 900;
    font-size: 15px;
}

.Buttongrp {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.DwldButton {
    padding: 10px 28px;
    border-radius: 10px;
    margin-bottom: 15px;
    font-family: var(--popinFont);
    font-weight: 800;
    margin-bottom: 15px;
    color: #FFFFFF;
    border: none;
    background-color: #c2272f;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.backButton {
    padding: 10px 28px;
    border-radius: 10px;
    margin-bottom: 15px;
    font-family: var(--popinFont);
    font-weight: 800;
    margin-bottom: 15px;
    color: #FFFFFF;
    border: none;
    background-color: blue;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.Header {
    text-align: center;
    margin: 10px 0;
    font-family: var(--popinFont);
    font-weight: 700;
    color: green;
}

.InvoiceDecreaseWidthContainer {
    width: 55%;
    background-color: #FFFFFF;
    margin: 0 auto;
    padding: 35px 40px 10px 40px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding-bottom: 5rem;
}

.InvoiceLogo {
    margin-bottom: 15px;
}

.InvoiceHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.LogoSideContainer h4 {
    font-family: var(--interFont);
    font-weight: 400;
    color: #232322;
    font-size: 18px;
}

.InvoiceSideContainer {
    width: 32%;
    text-align: end;
}

.Invoice {
    font-family: var(--popinFont);
    font-weight: 700;
    margin-bottom: 10px;
    color: #2a2a2a;
}

.InvoiceDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.Details {
    display: flex;
    align-items: start;
    gap: 30px;
}

.TdHead {
    width: 100px;
}

.SingleTable {
    margin-top: 5px;
}

.LogoSideContainer h2 {
    font-family: var(--interFont);
    font-weight: 600;
    font-size: 20px;
    margin-top: 5px;
    color: #2a2a2a;
}

.Para {
    font-size: 16px;
    font-weight: 400;
    font-family: var(--interFont);
    margin-top: 5px;
}

.MarginTop {
    margin-top: 35px;
}

.MainTable {
    margin-top: 20px;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
}

.MainTableHeaderRow {
    display: flex;
    justify-content: space-between;
    background-color: #c2272f;
    color: white;
    width: 100%;
    border-radius: 5px;
}

.MainTableRow {
    display: flex;
    justify-content: space-between;
}

.MainTable th {
    padding: 12px 10px;
    text-align: center;
    width: 25%;
}

.MainTable td {
    padding: 10px 10px;
    text-align: center;
    width: 25%;
}

.MainTable td {
    border-bottom: 1.5px solid #c2272f;
}

.FooterSection {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
}

.TotalBtn h4 {
    font-size: 20px;
    font-weight: bolder;
}

.TotalBtn p {
    font-size: 20px;
    font-weight: bolder;
}

.TermsAndConditionContainer {
    width: 50%;
}

.TotalSection {
    width: 28%;
    padding-bottom: 3rem;
}

.TermsAndConditionContainer h3 {
    font-family: var(--interFont);
    font-weight: 600;
    color: #2a2a2a;
    font-size: 16px;
    margin-top: 15px;
}

.TermsContent {
    font-size: 16px;
    font-weight: 400;
    font-family: var(--interFont);
    margin: 10px 0 25px 0;
    line-height: 20px;
}

.Mtop {
    margin-top: 20px;
}

.SocialDetails {
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 13px;
}

.TopSpace {
    margin-top: 15px;
}

.SocialIcons {
    color: #c2272f;
    font-size: 25px;
}

.TotalContainers {
    margin-top: 10px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TotalBtn {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    padding: 7px 19px;
    background-color: #c2272f;
    color: white;
    border-radius: 7px;
}




@media(max-width:1200px) {
    .InvoiceDecreaseWidthContainer {
        width: 80%;
    }
}

@media(max-width:600px) {
    .InvoiceDecreaseWidthContainer {
        width: 95%;
        padding: 15px;
    }

    .InvoiceSideContainer {
        width: 50%;
    }

    .InvoiceLogo {
        height: 50px;
    }

    .Invoice {
        font-size: 20px;
    }

    .Para {
        font-size: 12px;
    }

    .MarginTop {
        margin-top: 20px;
    }

    .SingleTable {
        width: 100%;
    }

    .MainTable th,
    .MainTable td {
        padding: 5px 10px;
        font-size: 10px;
    }

    .TermsAndConditionContainer {
        width: 45%;

    }

    .TotalSection {
        width: 45%;
        height: 100%;
        text-align: end;
    }

    .SocialIcons {
        font-size: 10px;
    }

    .TermsAndConditionContainer h3 {
        font-size: 12px;
    }

    .TermsAndConditionContainer p {
        font-size: 10px;
        line-height: 10px;
    }

    .TotalContainers h4 {
        font-size: 12px;
    }

    .TotalContainers p {
        font-size: 9px;
    }

    .TotalBtn {
        font-size: 12px;
    }
}