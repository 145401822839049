.ContactContainer {
    width: 90%;
    margin: 30px auto;
    display: flex;
    gap: 20px;
    margin-top: 100px;
}

.CantactForm {
    width: 65%;
    background-color: #F9F9F9;
    border-radius: 10px;
    padding: 20px;
}

.ContactUsHeading {
    font-size: 24px;
    font-weight: 500;
    font-family: var(--interFont);
}

.UnderLine {
    width: 60px;
    height: 3px;
    background-color: red;
    margin-top: 3px;
}

.InputSectionsContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 30px auto 0 auto;
}

.InputSectionsContainer input {
    width: 100%;
    padding: 12px 15px;
    border: none;
    border: 1px solid #C4C4C4;
    border-radius: 2px;
    background: none;
}

.InputSectionsContainer input::placeholder {
    color: #A5A5A5;
    font-family: var(--poppinFont);
    font-size: 12px;
}

.InputSectionsContainer textarea {
    width: 100%;
    height: 150px;
    background: none;
    border-radius: 2px;
    border: none;
    border: 1px solid #C4C4C4
}

.InputSectionsContainer p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    font-family: var(--interFont);
}

.Message {
    width: 100%;
    height: 150px;
    padding: 0;
}

.InputSectionsContainer p span {
    color: #FF1414;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--interFont);
    margin-left: 1px;
}

.CaptchaContainer input {
    padding: 0 10px;
    height: 37px;
    width: 165px;
    border: 1px solid #C4C4C4;
    margin-top: 5px;
}

.CaptchaContainer {
    display: flex;
    gap: 20px;
}

.RandomDataContainer {
    height: 36px;
    width: 165px;
    display: flex;
    gap: 2px;
    border-bottom: 2px solid #ab8f94;
    background: url(../../Assets/images/CaptchaBgImg);
    background-size: cover;
    margin-top: 5px;
}

.SubmitBtn {
    width: 95px;
    height: 35px;
    background-color: #EA1818;
    border-radius: 4px;
    color: #FFFFFF;
    border: none;
    font-weight: 500;
    font-family: var(--interFont);
    font-size: 14px;
    margin-top: 10px;

}

.Contact {
    width: 100%;
    height: 100%;
    background-color: #F9F9F9;
    border-radius: 10px;
    padding: 20px 25px;
}

.ContactHeading {
    font-size: 24px;
    font-weight: 500;
    font-family: var(--interFont);
    color: #000000;
}

.ContactSubHeading {
    font-size: 24px;
    font-weight: 500;
    font-family: var(--interFont);
    color: #FF1414;
    margin-top: 25px;
}

.Contact p {
    color: #323232;
    font-weight: 400;
    font-size: 14px;
    font-family: var(--poppinFont);
    margin-top: 12px;
}

.AddressContent {
    width: 70%;
    line-height: 30px;
}

.Contact p span {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--poppinFont);
}

.Location {
    width: 90%;
    height: 490px;
    margin: 30px auto;
}

.Location iframe {
    width: 100%;
    height: 100%;
}

.CantactForm button {
    margin: 15px 0px 15px 40px;
}

@media(max-width: 1200px) {}

@media(max-width: 768px) {

    .ContactContainer {
        flex-direction: column;
        width: 90%;
        margin-top: 150px;

    }

    .CantactForm {
        width: 100%;
    }

    .Contact {
        width: 100%;
    }

    .Location {
        height: 200px;
    }

    .CantactForm {
        padding: 0;
    }

    .ContactUsHeading {
        padding-left: 20px;
        padding-top: 20PX;
    }

    .UnderLine {
        margin-left: 20px;
    }

    .CantactForm button {
        margin: 15px 0px 15px 20px;
    }
}