.List1 {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px 50px 0px;
}

.List2 {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.headingdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headingclear {
    font-weight: 600;
    cursor: pointer;
    color: red;
}

.List3 {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    overflow-y: scroll;
}

.Search {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Search h1 {
    font-size: 18px;
}

.inputsearch {
    width: 100%;
    margin: 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 7px;
    padding: 0px 5px;
    margin-top: 10px;

}

.filteroption {
    background-color: white;
    margin: 20px 0px;
    padding: 10px;
    padding-right: 30px;
}

.filteroption h2 {
    border-bottom: 1px solid gray;
    font-size: 18px;
    padding-bottom: 6px;
}

.filteroption1 {
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.filteroption1 span p {
    width: 100%;
    cursor: pointer;
}

.filteroption1 span input {
    width: auto;
}

.filteroption2 {
    width: 100%;
    margin-top: 15px;
    display: flex;



    flex-direction: column;
}

.filtersort {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding-bottom: 6px;
}

.filtersort h2 {
    border: none;
}

.filteroption1 span {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
}

.filteroption1 span input {
    margin: 10px 0px;
    margin-left: 10px;
}

.inputsearch .ser {
    margin-left: 10px;
    font-size: 20px;
}

.inputsearch input {
    width: 100%;
    padding: 5px;
    height: 100%;
    border: none;
    outline: none;
    border-radius: 10px;

}

.List4 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    overflow-y: scroll;
}

.List4::-webkit-scrollbar {
    display: none;
}

.List3::-webkit-scrollbar {
    display: none;
}

.listcarts123 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-gap: 30px;
}

.Listpro {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.filteroption1 input {
    width: 100%;
}

.rangeselect {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 10px;
}

.rangeselect button {
    padding: 6px 10px;
    background-color: red;
    color: white;
    font-size: 16px;
    border-radius: 10px;
    border: none;
}

.rangeselect p {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rangeselect p span h3 {
    font-size: 16px;
}

@media screen and (max-width:768px) {
    .List1 {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0px 50px 0px;
        padding: 20PX;
    }

    .List2 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 0;
    }

    .List3 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px;
    }

    .Search {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .Search h1 {
        font-size: 18px;
    }

    .inputsearch {
        width: 100%;
        margin: 5px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border-radius: 7px;
        padding: 0px 5px;
        margin-top: 10px;
        border: 1PX solid gainsboro;

    }

    .filteroption {
        background-color: white;
        margin: 20px 0px;
        padding: 10px;
        padding-right: 30px;
    }

    .filteroption h2 {
        border-bottom: 1px solid gray;
        font-size: 18px;
        padding-bottom: 6px;
    }

    .filteroption1 {
        width: 100%;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .filteroption1 span p {
        width: 100%;
    }

    .filteroption1 span input {
        width: auto;
    }

    .filteroption2 {
        width: 100%;
        margin-top: 15px;
        display: flex;



        flex-direction: column;
    }

    .filtersort {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid gray;
        padding-bottom: 6px;
    }

    .filtersort h2 {
        border: none;
    }

    .filteroption1 span {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: space-between;
        font-size: 14px;
    }

    .filteroption1 span input {
        margin: 10px 0px;
        margin-left: 10px;
    }

    .inputsearch .ser {
        margin-left: 10px;
        font-size: 20px;
    }

    .inputsearch input {
        width: 100%;
        padding: 5px;
        height: 100%;
        border: none;
        outline: none;
        border-radius: 10px;

    }

    .List4 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0px;
        overflow-y: scroll;
    }

    .List4::-webkit-scrollbar {
        display: none;
    }

    .listcarts123 {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 9fr);
        grid-gap: 10px;
        margin-top: 20px;
        overflow-x: hidden;
    }

    .Listpro {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filteroption1 input {
        width: 100%;
    }

    .rangeselect {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 10px;
    }

    .rangeselect button {
        padding: 6px 10px;
        background-color: red;
        color: white;
        font-size: 16px;
        border-radius: 10px;
        border: none;
    }

    .rangeselect p {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .rangeselect p span h3 {
        font-size: 16px;
    }

    .List4 h1 {
        font-size: 20px;
    }

    .Listpro p {
        font-size: 14px;
        width: 50%;
        text-align: justify;
    }

    .Listpro h4 {
        font-size: 10px;
    }

}